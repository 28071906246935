<template lang="pug">
  .news-card
    template(v-if="news")
      Breadcrumb(
        :current_path="{name: 'NewsCard', title: news.head}"
        :previous_path=`[{name: 'News', title: 'News'}]`
      )

      .container.news-card__container
        h1.title_2.news-card__title {{ news.head }}

        .news-card__content
          .news-card__image
            BaseImage(
              :path="news.banner"
              external
              :alt="news.head"
            )

          .news-card__text {{ news.text }}
    Loader(v-else).news-card__loader
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb'
import BaseImage from '@/components/common/BaseImage'
import Loader from '@/components/Loader'
import NewsService from '@/services/news.service'

export default {
  name: 'NewsItem',
  components: { Loader, BaseImage, Breadcrumb },
  data: () => ({
    news: null
  }),
  methods: {
    async getNews (new_id) {
      await NewsService.getNew({ new_id })
          .then(response => this.news = response.news[0])
    }
  },
  mounted () {
    this.getNews(this.$route.params.id)
  }
}
</script>
